










































































































import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  ServiceOrganizationCreateOrUpdateDto,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import {CodeToText, regionData} from "element-china-area-data";
import AbSelect from "@/components/AbSelect/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";

Vue.use(regionData, CodeToText);

@Component({
  components: {
    AbSelect,
    Ueditor,
  },
})
export default class CreateServiceOrganization extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId = 0;
  form: ServiceOrganizationCreateOrUpdateDto = {
    id: 0,
    name: "",
    contactName: "",
    contactPhone: "",
    address: "",
    basicInfo: "",
  };

  options: any = regionData;
  selectedOptions: string[] = ["", "", ""];
  ServiceOrganizationTypeList: DataDictionaryDto[] = [];
  submitting = false;
  roleRule = {
    name: [
      {
        required: true,
        message: "服务机构名称必填",
        trigger: "blur",
      },
      {
        max: 50,
        message: "最多输入200字符",
        trigger: "blur",
      }
    ],
    serviceOrganizationTypeId: [
      {
        required: true,
        message: "服务机构类型必填",
        trigger: "blur",
      },
    ],
    contactName: [
      {
        required: true,
        message: "负责人必填",
        trigger: "blur",
      },
      {
        max: 200,
        message: "最多输入200字符",
        trigger: "blur",
      }
    ],
    contactPhone: [
      {
        required: true,
        message: "负责人联系电话必填",
        trigger: "blur",
      },
      {
        required: true,
        pattern: /^1[345789]\d{9}$/,
        message: '请输入正确的联系电话',
        trigger: 'blur',
      },
    ],
    basicInfo: [
      {
        required: true,
        message: "基本情况必填",
        trigger: "blur",
      },
    ],
  };

  // 保存
  async save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.$route.params.id) {
          await api.serviceOrganization
            .update({
              body: this.form,
            })
            .then(() => {
              this.$router.back();
              this.$message.success("更新成功");
            });
        } else {
          await api.serviceOrganization
            .create({
              body: this.form,
            })
            .then(() => {
              this.$router.back();
              this.$message.success("新建成功");
            });
        }
      } else {
        this.$message.error("请检查表单");
      }
    });
  }

  async created() {
    await this.fetchServiceOrganizationTypeList();

    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await api.serviceOrganization.get({id: this.dataId}).then((res) => {
        this.form = {...res};
        this.selectedOptions = [
          res!.provinceId + "",
          res!.cityId + "",
          res!.areaId + "",
        ];
      });
    }

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async fetchServiceOrganizationTypeList() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ServiceOrganizationType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.ServiceOrganizationTypeList = res!.items!;
      });
  }

  //返回
  cancel() {
    this.$router.back();
  }

  //   省市区
  handleChange(value: any, label: any) {
    this.form!.provinceId = value[0];
    this.form!.cityId = value[1];
    this.form!.areaId = value[2];
  }
}
